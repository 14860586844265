import { ViewBasic } from '@quatrecentquatre/manage-me';

export class ProductPhoneContact extends ViewBasic {
    constructor(options) {
        super(options);

        this.phoneNumbers = document.querySelectorAll('#overlay-product-contact-phone .content a');
    }

    initialize() {
        this.bindAll(['openOverlay', 'dataLayerPush']);
        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener('click', this.openOverlay);
        this.phoneNumbers.forEach((el) => {
            el.addEventListener('click', this.dataLayerPush);
        });
    }

    removeEvents() {
        this.el.removeEventListener('click', this.openOverlay);
        this.phoneNumbers.forEach((el) => {
            el.removeEventListener('click', this.dataLayerPush);
        });
    }

    openOverlay() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'call-us',
        });

        window.dispatchEvent(
            new CustomEvent('Overlay.open', {
                detail: {
                    id: 'overlay-product-contact-phone',
                },
            }),
        );
    }

    dataLayerPush(e) {
        e.preventDefault();

        const continent = e.currentTarget.closest('.region').querySelector('strong').innerText;
        const telHref = e.currentTarget.getAttribute('href');

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'call-us-phone', region: continent });

        // Allow time for dataLayer push before redirecting
        setTimeout(() => {
            window.location.href = telHref; // Trigger the phone call
        }, 100);
    }
}

Me.views['ProductPhoneContact'] = ProductPhoneContact;
